<script context="module">
  export const getDistinct = (items) => {
    return Array.from(new Set(items)).sort((a, b) => a - b);
  };
</script>

<script>
  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";

  import { shippingAndReturnStatusType } from "~/libs/constants";

  const dispatch = createEventDispatcher();

  export let filterValue;
  export let preFilteredValues;
  export let results;
  export let columnId;

  $: uniqueValues = getDistinct($preFilteredValues);

  let countMap = new Map();

  (() => {
    if (columnId === "status") {
      results.forEach((result) => {
        if (countMap.get(result.shippingAndReturnStatus) === undefined) {
          countMap.set(result.shippingAndReturnStatus, 1);
        } else {
          countMap.set(
            result.shippingAndReturnStatus,
            countMap.get(result.shippingAndReturnStatus) + 1,
          );
        }
      });
    } else if (columnId === "driverDisplayName") {
      results.forEach((result) => {
        const matchingDriverDisplayName = result.driverDisplayName
          ? result.driverDisplayName
          : "未登録";
        if (countMap.get(matchingDriverDisplayName) === undefined) {
          countMap.set(matchingDriverDisplayName, 1);
        } else {
          countMap.set(
            matchingDriverDisplayName,
            countMap.get(matchingDriverDisplayName) + 1,
          );
        }
      });
    } else if (columnId === "companyName") {
      results.forEach((result) => {
        const matchingCompanyName = result.companyName ?? "未登録";
        if (countMap.get(matchingCompanyName) === undefined) {
          countMap.set(matchingCompanyName, 1);
        } else {
          countMap.set(
            matchingCompanyName,
            countMap.get(matchingCompanyName) + 1,
          );
        }
      });
    }
  })();

  function getDisplayStatus(value) {
    if (value.substring(0, 1) === "2") {
      const returnStatusType = value.substring(0, 2);
      const returnReasonText = $_(
        `classes.returnReasonShort.${value.substring(2, 3)}`,
      );
      switch (returnStatusType) {
        case shippingAndReturnStatusType.RETURN_REQUESTING:
          return `${$_("classes.returnStatusShort.0")}(${returnReasonText})`;
        case shippingAndReturnStatusType.RETURN_WAITING:
          return `${$_("classes.returnStatusShort.1")}(${returnReasonText})`;
        case shippingAndReturnStatusType.RETURNING:
          return `${$_("classes.returnStatusShort.2")}(${returnReasonText})`;
        case shippingAndReturnStatusType.RETURN_COMPLETE:
          return `${$_("classes.returnStatusShort.3")}(${returnReasonText})`;
      }
    } else {
      switch (value) {
        case shippingAndReturnStatusType.CREATED:
          return $_("classes.status.0");
        case shippingAndReturnStatusType.IN_TRANSIT:
          return $_("classes.status.1");
        case shippingAndReturnStatusType.HELD_IN_DEPOT:
          return $_("classes.status.2");
        case shippingAndReturnStatusType.OUT_FOR_DELIVERY:
          return $_("classes.status.3");
        case shippingAndReturnStatusType.DELIVERED:
          return $_("classes.status.4");
        case shippingAndReturnStatusType.CANCELLED:
          return $_("classes.shipmentCanceled");
        case shippingAndReturnStatusType.UNDELIVERABLE_WITH_LOST:
          return $_("classes.lost");
        case shippingAndReturnStatusType.UNDELIVERABLE_WITH_MISDELIVERY:
          return $_("classes.misdeliveryState");
      }
    }
  }
</script>

<select
  bind:value={$filterValue}
  on:click={(event) => {
    event.stopPropagation();
  }}
  on:change={() => {
    dispatch("filter", { value: $filterValue });
  }}
>
  {#if columnId === "status"}
    <option value={undefined}>全て</option>
    {#each uniqueValues as value}
      <option {value}
        >{`${getDisplayStatus(value)}(${countMap.get(value).toLocaleString()})`}</option
      >
    {/each}
  {:else}
    <option value={undefined}>全て</option>
    {#each uniqueValues as value}
      <option {value}
        >{`${value}(${countMap.get(value).toLocaleString()})`}</option
      >
    {/each}
  {/if}
</select>

<style lang="scss">
  select {
    font-size: 12px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
  }
</style>
